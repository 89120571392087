import { merge } from 'lodash-es'

export const getDefaultNotifications = (data: Partial<Notification>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'info',
  name: '',
  description: '',
  optionsLocal: {},
  optionsPush: {},
}, data)

export const optionsNotifications = {
  type: [
    {
      value: 'info',
      label: 'Información',
      color: 'blue',
      icon: 'i-mdi-information',
    },
    {
      value: 'success',
      label: 'Éxito',
      color: 'green',
      icon: 'i-mdi-check-circle',
    },
    {
      value: 'warning',
      label: 'Advertencia',
      color: 'amber',
      icon: 'i-mdi-alert',
    },
    {
      value: 'error',
      label: 'Error',
      color: 'red',
      icon: 'i-mdi-alert-circle',
    },
  ],
} satisfies Options

// runtime:

export const createNotification = async (
  notification: Notification,
  userIds: Usernotification['userId'][],
) => {
  const { onSubmitPOST } = useApiDB<Notification>('notifications')
  const { onSubmitPOST: onSubmitPOSTUsernotification } = useApiDB<Usernotification>('usernotifications')

  if (userIds.length) {
    const data = await onSubmitPOST(getDefaultNotifications(notification))

    await Promise.all(userIds.map(async (userId) => {
      await onSubmitPOSTUsernotification({
        ...data,
        userId,
        notificationId: data!.id,
        read: false,
      })
    }))
  }
}
