import { ModalDelete, UsernotificationsSlideoverList } from '#components'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

export type UsernotificationsQuery = ViewQuery & {
  type?: Usernotification['type']
  deletedAt?: '$isNull' | '$isNotNull'
}

type UsernotificationsSlideoverListProps = ComponentProps<typeof UsernotificationsSlideoverList>

export type UsernotificationsUtils = ReturnType<typeof useUsernotificationsUtils>

// view:

export const VIEW_SETTINGS_NOTIFICATIONS: ViewSettings<Usernotification> = {
  tableName: 'usernotifications',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'notificationType',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'notificationName',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'notificationDescription',
      label: 'Descripción',
    },
    ...COLUMNS_METADATA,
  ],
  visibleColumns: [
    'actions',
    'notificationType',
    'notificationName',
    'notificationDescription',
  ],
}

export const useUsernotifications = () => {
  const slideover = useSlideover()
  const modal = useModal()

  // forms:

  const {
    onSubmitPOST,
    onSubmitPATCH,
    onSubmitDELETE,
    onSubmitPATCHMultiple,
  } = useApiDB<Usernotification>('usernotifications')

  const slideoverOpenList = (props: UsernotificationsSlideoverListProps) => {
    slideover.open(UsernotificationsSlideoverList, {})
  }

  const modalOpenDelete = (props: ComponentProps<typeof ModalDelete>) => {
    modal.open(ModalDelete, {
      title: 'Eliminar notificación',
      onSubmit: props?.onSubmit ?? (async (event) => {
        const result = await onSubmitDELETE([event.data])
        modal.close()
        return result
      }),
    })
  }

  // actions:

  const { user } = useUserSession()

  const getDropdownItems = (row: Usernotification, refresh: () => Promise<void>, utils: UsernotificationsUtils, dropdownItemsAction: DropdownItem[][] = []): DropdownItem[][] => [
    [
      {
        label: row.read ? 'Marcar como no leído' : 'Marcar como leído',
        icon: row.read ? 'i-mdi-check-circle' : 'i-mdi-circle-outline',
        click: async () => {
          await onSubmitPATCH({ id: row.id, read: !row.read })
          slideover.close()
          await refresh()
        },
      },
    ],
    ...dropdownItemsAction,
  ]

  return {
    onSubmitPOST,
    onSubmitPATCH,
    onSubmitDELETE,
    onSubmitPATCHMultiple,
    slideover,
    slideoverOpenList,
    modal,
    modalOpenDelete,
    getDropdownItems,
  }
}

export const useUsernotificationsUtils = ({ $users }: {
  $users: Awaited<ReturnType<typeof useFetch<User[]>>>
}) => {
  const options = {
    type: optionsNotifications.type,
    userId: computed(() => $users.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsNotifications.type, 'value'),
    userId: computed(() => toMapByKey($users.data.value, 'id')),
  }

  return { options, maps }
}
